<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";

  import { cart } from "../stores.js";

  // Carbon Svelte
  import { Tile, Button, Tag } from "carbon-components-svelte";
  import Add16 from "carbon-icons-svelte/lib/Add16";
  import ShoppingCartPlus16 from "carbon-icons-svelte/lib/ShoppingCartPlus16";

  import AddToCartButton from "./AddToCartButton.svelte";

  export let product;
  let isHovering = false;

  const addToCart = item => {
    cart.update(items => [...items, item]);
  };
</script>

<style lang="scss">
  :global(:root) {
    --cds-hover-selected-ui: rgba(0, 0, 0, 0.15);
    --product-tile-h: 200px;
  }

  :global(.product-tile) {
    flex-basis: 50%;
    transition: all 200ms ease-in-out;
    //height: var(--product-tile-h);
    cursor: pointer;
  }

  :global(.focus-border) {
    position: absolute;
    left: 50%;
    top: 0;
    height: 2px;
    background-color: var(--cds-interactive-04);
    opacity: 0.8;
    width: 0;
    transition: all 0.15s ease-out;
  }

  :global(.product-tile:hover) {
    background-color: var(--cds-hover-selected-ui);
    /*filter: brightness(0.9);
    > .focus-border {
      width: 100%;
      left: 0;
    }*/
  }

  .row {
    display: flex;
    height: 100%;
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .product-image {
    height: 8rem;
    width: auto;
    mix-blend-mode: multiply;
    //transform: translateX(calc(-50% + 24px));
  }

  .text {
    //transform: translate(-60px, 6px);
    padding: 4px 0px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .name {
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .description {
    flex: 1;
    white-space: pre-wrap;
  }

  .brand {
    font-family: monospace;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.85);
    text-transform: uppercase;
    text-decoration: none;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  .hidden {
    visibility: hidden;
  }

  @media screen and (max-width: 768px) {
    .product-image {
      height: 6rem;
    }

    .name {
      font-size: 1.05rem;
    }
  }
</style>

<Tile
  on:mouseenter={() => (isHovering = true)}
  on:mouseleave={() => (isHovering = false)}
  class="product-tile">
  <div class="focus-border" />
  <div class="row" on:click={() => push(`/product/${product._id}`)}>
    <div class="col">
      <img src={'/img/' + product.img} class="product-image" alt="" />
    </div>
    <div
      class="col"
      style="justify-content: space-between; width: 100%; padding-left: 0.5rem">
      <div class="row" style="width: 100%;">
        <div class="text">
          <div class="name">{product.name}</div>
          <a
            class="brand"
            on:click|stopPropagation
            href={`/#/brand/${product.brand._id}`}>
            {product.brand.name}
          </a>
          <!--<pre class="description">{product.description}</pre>-->
          <div style="flex: 1" />
        </div>
      </div>
      <div
        class="row"
        style="width: 100%; height: fit-content; align-items: center;
        justify-content: space-between;">
        <div class="price">{product.price} PLN</div>
        <div
          class="add-to-cart"
          on:click|stopPropagation
          class:hidden={!isHovering}>
          <AddToCartButton
            store={cart}
            item={product}
            style="min-width: 0px;" />
        </div>
      </div>
    </div>
  </div>
</Tile>
