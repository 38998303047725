<script>
  import BrandImage from "../svg/sk8store.svg";
</script>

<style lang="scss">
  .container {
  }

  :global(.container svg) {
    height: 100%;
    width: auto;
  }
</style>

<div class={`container ${$$props.class}`}>
  <BrandImage />
</div>
