<script>
  // Shopping cart store
  //import { cart } from "../stores.js";

  // Carbon components
  import { Button, Tag } from "carbon-components-svelte";

  // Carbon icons
  import ShoppingCartPlus16 from "carbon-icons-svelte/lib/ShoppingCartPlus16";
  import Checkmark16 from "carbon-icons-svelte/lib/Checkmark16";

  export let isAdded = false;
  export let store;
  export let item;
  let cart = $store;
  let btnStyle;
  //const unsub = store.subscribe(c => (cart = c));

  $: if ($store && item) {
    isAdded = $store.find(it => it._id === item._id) ? true : false;
  }

  $: btnStyle = isAdded ? "width: 125px;" : "";

  const handleCLick = () => {
    if (!isAdded) {
      store.update(c => [...c, item]);
    }
  };
</script>

<style lang="scss">
  :global(.bx--btn--tertiary) {
    color: var(--cds-interactive-03);
    width: max-content;
    width: 155px;
    transition: width 0.2s ease-out;
  }

  :global(.bx--btn--tertiary:active) {
    background-color: var(--cds-interactive-03);
    color: rgba(255, 255, 255, 0.85);
  }
</style>

<Button
  kind="tertiary"
  icon={isAdded ? Checkmark16 : ShoppingCartPlus16}
  on:click={handleCLick}
  style={btnStyle}>
  {isAdded ? 'In cart' : 'Add to cart'}
</Button>
