<script>
  import { onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { fade, fly } from "svelte/transition";

  // Custom components
  import TabbedProducts from "../components/TabbedProducts.svelte";
  import Carousel from "../components/Carousel.svelte";

  // Carbon svelte
  import { Tile, Icon } from "carbon-components-svelte";
  import Delivery20 from "carbon-icons-svelte/lib/Delivery20";
  import DeliveryParcel20 from "carbon-icons-svelte/lib/DeliveryParcel20";
  import DirectionUTurn20 from "carbon-icons-svelte/lib/DirectionUTurn20";
  import ShoppingCartArrowDown20 from "carbon-icons-svelte/lib/ShoppingCartArrowDown20";

  import { authorize, fetchProducts } from "../db.js";
  import { products as products_store } from "../stores.js";

  let products = [];
  let stripItems = [
    { id: 0, text: "Free delivery for orders over $25", icon: Delivery20 },
    { id: 1, text: "2 weeks of free returns", icon: DirectionUTurn20 },
    {
      id: 2,
      text: "Free accessory with every order",
      icon: ShoppingCartArrowDown20
    }
  ];
  let carouselItems = [
    { src: "/img/main.png" },
    { src: "/img/main-2.png" },
    { src: "/img/main-3.png" },
    { src: "/img/main-4.png" }
  ];
  let carouselImg;

  $: if ($products_store) {
    products = $products_store;
  }

  onMount(async () => {
    //await authorize();
    if (!$products_store) {
      products = await fetchProducts();
    }
  });
</script>

<style lang="scss">
  .container {
    display: grid;
    grid-template-rows: auto 1fr 2fr;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .top-strip {
    min-height: 32px;
    height: fit-content;
    background-color: var(--cds-ui-03);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--cds-interactive-01);
    border-top: none;
    box-sizing: border-box;
  }

  .top-strip--item {
    padding: 2px 10px;
    display: flex;
    align-items: center;
  }

  .top-strip--item--text {
    margin-left: 6px;
  }

  .main-photo {
    overflow: hidden;
    width: 100%;
  }

  .wrap {
    position: absolute;
    //height: 420px;
  }

  :global(.main-photo--tile) {
    --cds-hover-selected-ui: rgba(0, 0, 0, 0.85);
    //background-color: rgba(0, 0, 0, 0.85);
    //color: rgba(255, 255, 255, 0.8);
    background-color: var(--cds-ui-01);
    color: rgba(0, 0, 0, 0.85);
    width: 200px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    transition: all 300ms ease-in-out;
  }

  :global(.main-photo--tile::before) {
  }

  .main-photo--tile--content {
    width: 200px;
  }
</style>

<div class="container">
  <div class="top-strip">
    {#each stripItems as item (item.id)}
      <div
        class="top-strip--item"
        in:fly={{ y: -20, delay: 200 * item.id * 1.25 }}
        animate:flip>
        <Icon render={item.icon} />
        <div class="top-strip--item--text">{item.text}</div>
      </div>
    {/each}
  </div>
  <div class="row" style="height: 420px">
    <div class="main-photo">
      <Carousel items={carouselItems} bind:currentImage={carouselImg} />
      <!--<img src="/img/main.png" />-->
    </div>
    <!--<Tile class="main-photo--tile">
      <div class="main-photo--tile--content">Fan photos</div>
    </Tile>-->
  </div>

  <!-- Shopping category links -->
  <TabbedProducts {products} />
</div>
