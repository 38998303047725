<script>
  import { cart } from "../stores.js";
  import { fly, fade } from "svelte/transition";

  import {
    DataTable,
    DataTableSkeleton,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    ToolbarMenu,
    ToolbarMenuItem,
    ToolbarBatchActions,
    Button
  } from "carbon-components-svelte";

  import Delete20 from "carbon-icons-svelte/lib/Delete20";

  const headers = [
    { key: "name", value: "Product" },
    { key: "price", value: "Price" },
    { key: "quantity", value: "Quantity" }
  ];
  let items;
  let total;

  $: if ($cart) {
    let cartItems = $cart;
    items = cartItems.reduce((res, val) => {
      let item = {
        ...val,
        id: val._id,
        quantity: 1
      };

      let cartIdx = res.findIndex(c => c.id === item.id);
      if (cartIdx > -1) {
        res[cartIdx]["quantity"] += 1;
      } else {
        res.push(item);
      }
      return res;
    }, []);
    console.log(items);
  }

  $: if (items) {
    total = items.reduce((res, val) => {
      return res + val.price * val.quantity;
    }, 0);
  }

  const clearCart = () => {
    $cart = [];
  };
</script>

<style lang="scss">
  .container {
    width: 100%;
    margin: 0 auto;
    padding-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
  }

  .section-title {
    margin-bottom: 1.5rem;
  }

  .invoice {
    width: 100%;
  }

  .summary {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .summary-total {
    display: flex;
    align-items: center;

    > h5 {
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
  }

  .table-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  :global(.bx--action-list .bx--btn) {
    background-color: var(--bg);
  }
</style>

<div class="container">
  <div class="invoice">
    <h3 class="section-title">Shopping cart</h3>
    {#if !items}
      <DataTableSkeleton showHeader={false} showToolbar />
    {:else if items.length > 0}
      <DataTable {headers} rows={items} batchSelection>
        <Toolbar>
          <ToolbarBatchActions>
            <Button icon={Delete20}>Remove</Button>
          </ToolbarBatchActions>
          <ToolbarContent />
        </Toolbar>
      </DataTable>
    {:else}
      <span>Nothing here yet.</span>
    {/if}
  </div>
  <div class="pane">
    <h3>2nd pane</h3>
  </div>
  {#if items.length > 0}
    <div class="summary" in:fly={{ x: -20 }}>
      <div class="summary-total">
        <h5>Total:</h5>
        <span>{total} PLN</span>
      </div>
      <Button kind="ghost" on:click={clearCart}>Remove all</Button>
    </div>
  {/if}
</div>
