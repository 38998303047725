<script>
  // Carbon components
  import { ButtonSet, Button } from "carbon-components-svelte";
  import ShoppingCart16 from "carbon-icons-svelte/lib/ShoppingCart16";
  import Information16 from "carbon-icons-svelte/lib/Information16";

  // Custom components
  import Logo from "./Logo.svelte";

  import { onMount } from "svelte";
  import { cart } from "../stores.js";

  let uCart = [];
  let cartBtnEl;
  const unsub = cart.subscribe(val => (uCart = val));

  let isMobile = false;

  const categories = [
    { label: "Skateboards", href: "#" },
    { label: "Clothing", href: "#" },
    { label: "Shoes", href: "#" }
  ];

  const routes = [
    { label: "Cart", href: "#" },
    { label: "About us", href: "#" },
    { label: "Contact", href: "#" }
  ];

  onMount(() => {
    window.addEventListener("resize", () => {
      isMobile = window.screen.width <= 768;
    });
    isMobile = window.screen.width <= 768;
  });

  $: if (cartBtnEl) {
    console.log(cartBtnEl);
  }
</script>

<style lang="scss">
  :global(:root) {
    /* Carbon Components Svelte SaSS overrides */

    /* button bg color */
    --cds-interactive-01: rgba(0, 0, 0, 0.85);
    /* button bg color on hover */
    --cds-hover-primary: #383838;
    /* button text color on hover */
    --cds-text-04: #27ae60;
    /* button bg color on active */
    --cds-active-primary: /*#1abc9c;*/ rgba(0, 0, 0, 0.85);
    /* button border color on focus */
    --cds-focus: #23824a;

    /* Tertiary button text color */
    --cds-interactive-03: #23824a;
    /* Tertiary button text color on hover */
    //--cds-inverse-01: #23824a;
    /* Tertiary button fill color on hover */
    --cds-hover-tertiary: #23824a;

    /* selected tab item border color */
    --cds-interactive-04: #23824a;

    /*  */
  }

  :global(a:visited) {
    color: rgba(255, 255, 255, 0.8);
  }

  .nav {
    //padding-top: 16px;
    //padding-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    background-color: var(--cds-interactive-01);
    //margin-bottom: 1rem;
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .nav-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  :global(.logo) {
    position: relative;
    //background-color: rgba(0, 0, 0, 0.85);
    height: 72px;
    padding: 10px 14px;
  }

  :global(.top-route) {
    display: flex;
    flex: 1;
    background-color: transparent;
  }

  .cart-n {
    //position: absolute;
    //right: 0;
    transform: translateX(50%);
    font-size: 0.75em;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }

  :global(.bx--btn--primary) {
    //color: #27ae60;
    //background-color: rgba(0, 0, 0, 0.85);
    width: fit-content;
    white-space: nowrap;
  }

  :global(.bx--btn--primary:hover) {
    //color: #1abc9c;
    //background-color: #404040;
  }

  .inline-block-wrap {
    display: inline-block;
  }

  .top-routes {
    display: grid;
    grid-template-columns: repeat(2, auto);
    height: 100%;
  }

  .cart-btn--content {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    :global(.logo) {
      max-height: 3rem;
      padding: 8px 6px;
    }

    :global(.top-route) {
      height: 0;
      width: 0;
      padding: 1.5rem;
    }

    .cart-btn--content {
      visibility: collapse;
    }
  }
</style>

<div class="nav">
  <div class="nav-row">
    <a href="/#">
      <Logo class="logo" />
    </a>
    <div class="inline-block-wrap">
      <div class="top-routes">
        <Button
          size="small"
          href="#/cart"
          class="top-route"
          icon={ShoppingCart16}>
          <div class="cart-btn--content">
            Cart
            <div class="cart-n">({uCart.length})</div>
          </div>
        </Button>
        <Button size="small" href="#" class="top-route" icon={Information16}>
          <div class="cart-btn--content">About us</div>
        </Button>
      </div>
    </div>
  </div>
</div>
