<script>
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";
  import { products as products_store } from "../stores.js";
  import ProductTile from "../components/ProductTile.svelte";

  // Carbon Svelte
  import { Tag, Button, MultiSelect, Loading } from "carbon-components-svelte";

  // URL params
  export let params = {};

  // data
  let products = [];

  // filterable values
  let brands = [];
  let categories = [];

  /*
    Filters to be applied
    keys: fields to filter by
    values: arrays of allowed ids
  */
  let filters = {};

  $: if ($products_store) {
    products = $products_store;
  }

  // Extract all unique brands and categories
  $: if (products) {
    products.forEach(p => {
      let { brand, category } = p;
      if (!brands.some(b => b.id === brand._id)) {
        brands = [...brands, { id: brand._id, text: brand.name }];
      }

      if (!categories.some(c => c.id === category._id)) {
        categories = [...categories, { id: category._id, text: category.name }];
      }
    });
  }

  // If there are any fields to filter by
  $: if (filters !== {} && Object.keys(filters).length > 0) {
    resetFilters();
    applyFilters(filters);
  } else {
    resetFilters();
  }

  onMount(async () => {
    let { id } = params;
    let hash = window.location.hash;
    let key = hash.split("/")[1];
    addFilter({ key, value: id });
    //console.log("on mount filters", filters);
  });

  const filtersApplied = () => {
    if (filters === {} || Object.values(filters).every(f => f === [])) {
      return false;
    } else {
      return true;
    }
  };

  const resetFilters = () => {
    products = $products_store;
  };

  const applyFilters = filters => {
    let fields = Object.keys(filters);

    products = products.reduce((res, val) => {
      // Iterate over fields we filter by on each product
      // If there's a matching id it's added to results array
      let matchesFilter = fields.every(f => {
        // If filters are empty allow all products through
        if (filters[f] === [] && !res.find(v => v._id === val._id)) {
          return true;
        }

        // If any of the filters don't match, reject the product
        if (![...filters[f]].includes(val[f]["_id"])) {
          return false;
        }

        // Product meets all filter conditions
        return true;
      });
      if (matchesFilter) {
        res.push(val);
      }
      return res;
    }, []);
  };

  // filter: { key: <field>, value: <filter value>}
  const addFilter = filter => {
    let fieldFilters = filters[filter.key] || [];
    fieldFilters.push(filter.value);
    filters[filter.key] = fieldFilters;
    filters = filters;
  };

  const handleBrandFilter = e => {
    const { selectedIds } = e.detail;
    //products = products.filter(p => selectedIds.includes(p.brand._id));

    if ([...selectedIds].length > 0) {
      filters = {
        ...filters,
        brand: selectedIds
      };
    } else {
      delete filters["brand"];
      filters = filters;
    }
  };

  const handleCategoryFilter = e => {
    const { selectedIds } = e.detail;
    if ([...selectedIds].length > 0) {
      filters = {
        ...filters,
        category: selectedIds
      };
    } else {
      delete filters["category"];
      filters = filters;
    }

    //products = products.filter(p => )
  };
</script>

<style lang="scss">
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0.5rem 0rem;
    gap: 0.5rem;
  }

  .filters {
    padding-top: 1.75rem;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 0.5rem;
    align-items: flex-end;
  }

  :global(.bx--multi-select__wrapper) {
    //margin-right: 0.75rem;
  }

  .fs-center {
    display: flex;
    width: 100%;
    height: 65vh;
    align-items: center;
    justify-content: center;
  }
</style>

<div class="filters">
  <MultiSelect
    titleText="Filters"
    label="Filter brands"
    selectedIds={filters['brand'] || []}
    items={brands}
    on:select={handleBrandFilter} />
  <MultiSelect
    label="Filter categories"
    selectedIds={filters['category'] || []}
    items={categories}
    on:select={handleCategoryFilter} />
</div>

{#if products.length === 0 && $products_store.length === 0}
  <!-- Only when data loading -->
  <div class="fs-center" in:fly={{ y: -20 }}>
    <Loading small withOverlay={false} />
  </div>
{:else}
  <div class="container" in:fly={{ x: -40 }}>
    {#each products as p}
      <ProductTile product={p} />
    {/each}
  </div>
{/if}
