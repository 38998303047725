import {
  writable
} from "svelte/store";

const createWritableStore = (key, startValue) => {
  const {
    subscribe,
    set,
    update,
  } = writable(startValue);

  return {
    subscribe,
    set,
    update,
    useLocalStorage: () => {
      const json = localStorage.getItem(key);
      if (json) {
        set(JSON.parse(json));
      }

      subscribe(current => {
        localStorage.setItem(key, JSON.stringify(current));
      });
    }
  };
}

const api_key = API_KEY

export const apikey = writable(api_key);
export const accessToken = writable(undefined);
export const refreshToken = writable(undefined);
export const products = writable([]);
export const cart = createWritableStore("cart", []);