<script>
  import { onMount } from "svelte";
  import { fly, fade } from "svelte/transition";
  import { fetchProduct } from "../db.js";

  import { cart } from "../stores.js";

  // Carbon Svelte
  import {
    Breadcrumb,
    BreadcrumbItem,
    Loading
  } from "carbon-components-svelte";

  import AddToCartButton from "../components/AddToCartButton.svelte";

  export let params;
  let { id } = params;
  let data;

  onMount(async () => {
    data = await fetchProduct(id);

    var replaced = data["description"].replaceAll("\\n", "<br>");
    data["description"] = replaced;
    data = data;
    console.log(data.description);
  });
</script>

<style lang="scss">
  .container {
    padding-top: 48px;
    //background-color: var(--cds-ui-01);
  }

  .product-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .product-info--image {
    > img {
      width: 400px;
      height: auto;
    }
  }

  .product-info--details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }

  .details-title {
    margin-bottom: 1.5rem;
  }

  .details-description {
    white-space: pre-line;
    min-height: 250px;
  }

  .details-price {
    font-size: 2rem;
    margin-right: 2rem;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .fs {
    width: 100%;
    height: calc(50vh);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .product-info {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    .product-info--image {
      display: flex;
      justify-content: center;
    }
  }
</style>

{#if data}
  <div class="container">
    <div class="product-info">
      <div class="product-info--image">
        <img src={`/img/${data.img}`} in:fade />
      </div>
      <div class="product-info--details">
        <h3 class="details-title">{data.name}</h3>
        <div class="details-description">
          {@html data.description}
        </div>
        <div class="row" in:fly={{ y: -20 }}>
          <div class="details-price">{data.price} PLN</div>
          <AddToCartButton store={cart} item={data} />
        </div>
      </div>
    </div>
  </div>
{:else}
  <div class="fs" in:fly={{ y: -50 }}>
    <Loading withOverlay={false} small />
  </div>
{/if}
