<script>
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";
  import { flip } from "svelte/animate";

  // The array of slides to show
  export let items = [];
  // Holds current slide object
  export let currentItem;

  let currentIndex = 3;
  // The background-image css style to inject
  let itemStyle;

  // Update current item according to active index
  $: if (items) {
    currentItem = items[currentIndex];
  }

  onMount(() => {
    setInterval(next, 10000);
  });

  export function next() {
    currentIndex = (currentIndex + 1) % items.length;
  }
</script>

<style>
  .carousel {
    height: 100%;
  }

  .slide {
    background-size: contain;
    background-repeat: round;
    height: 100%;
    width: 100%;
  }

  .slide img {
    width: 100%;
    transform: translateY(-5%);
  }
</style>

<div class="carousel">
  <!--{#key currentItem}
    <!--<div class="wrapper">
    </div>
    <img
      transition:fade
      src={item.src}
      bind:this={currentImage}
      class="carousel-img"
      alt="" />
  {/key}
    -->
  {#each [items[currentIndex]] as img (currentIndex)}
    <!--<div
      class="slide"
      style="background-image: url({img.src});"
      transition:fade>
      Sample text
    </div>-->
    <div class="slide" in:fade>
      <img src={img.src} />
    </div>
  {/each}
</div>
