import {
  apikey,
  accessToken,
  refreshToken
} from "./stores.js";

let app_id = "sk8shop-zcwgq";
let api_key;
let api_token;
let api_refresh;
let api_base_url = "https://realm.mongodb.com/api/client/v2.0/app/";
let api_token_url = api_base_url + app_id + "/auth/providers/api-key/login";
let api_graphql_url = api_base_url + app_id + "/graphql"

const unsub = apikey.subscribe(key => {
  api_key = key;
});

const uAccess = accessToken.subscribe(val => {
  api_token = val;
});

const uRefresh = refreshToken.subscribe(val => {
  api_refresh = val;
});

export const authorize = async () => {
  try {
    var response = await fetch(api_token_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        key: api_key
      })
    });
    var json = await response.json();
    accessToken.set(json["access_token"]);
    refreshToken.set(json["refresh_token"]);
    console.log("API authorized!")
  } catch (ex) {
    console.error(ex);
  }
}

export const runQuery = async (query) => {
  try {
    if (!api_token) {
      await authorize();
    }
    var response = await fetch(api_graphql_url, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${api_token}`,
      },
      body: JSON.stringify(query)
    });
    var json = (await response.json())["data"];
    //console.log(json);
    return json;
  } catch (ex) {
    console.error(ex);
  }
}

export const fetchProducts = async () => {
  try {
    var q = {
      query: `query {
        products {
          _id
          name
          category {
            _id
            name
          }
          brand {
            _id
            name
          }
          description
          img
          price
        }
      }`
    }
    var json = (await runQuery(q))["products"];
    //console.log(json);
    return json;
  } catch (ex) {
    console.error(ex);
  }
}

export const fetchProductsByBrandId = async (brandId) => {
  try {
    var q = {
      query: `query {
        products(query: { brand: { _id: "${brandId}" }}) {
          _id
          brand {
            _id
            name
          }
          category {
            _id
            name
          }
          name
          description
          price
        }
      }`
    };

    var json = (await runQuery(q))["products"];
    return json;
  } catch (ex) {
    console.error(ex);
  }
}

export const fetchProduct = async (id) => {
  try {
    var q = {
      query: `query {
        product(query: { _id: "${id}" }) {
          _id
          name
          category {
            _id
            name
          }
          brand {
            _id
            name
          }
          description
          img
          price
        }
      }`
    };

    console.log(q);

    var resp = await runQuery(q);
    //console.log(resp);
    return resp["product"];
  } catch (ex) {
    console.error(ex);
  }
}