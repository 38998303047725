<script>
  import {
    Tabs,
    Tab,
    TabContent,
    TabsSkeleton,
    Tile
  } from "carbon-components-svelte";
  
  import CTabItem from "./CTabItem.svelte";
  import ProductTile from "./ProductTile.svelte";

  import { fly } from "svelte/transition";

  // Number of columns to show products in
  export let columns = 2;
  // The list of products
  export let products = [];
  let data = [];
  let activeTab;

  /*
    Transforms list of product objects with categories into
    a list of category objects with arrays of product objs
  */
  $: if (products.length > 0) {
    data = products.reduce((res, p) => {
      let cat = p.category.name;
      if (!res[cat]) {
        res[cat] = [p];
      } else if (!res[cat].includes(p)) {
        res[cat].push(p);
      }
      return res;
    }, []);
    console.log(data);
    activeTab = Object.keys(data)[0];
  }

  function handleTabClick(event) {
    activeTab = event.detail.label;
  }
</script>

<style lang="scss">
  :global(.bx--tabs.bx--skeleton .bx--tabs__nav-link) {
    width: 160px;
    height: 48px;
  }

  .tab-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .bx--tabs--container {
    height: 40px;

  }

  @media screen and (max-width: 768px) {
    .tab-content {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>

<div class="tabbed-products">
{#if products.length === 0 || !products}
  <!-- Tab loading sekeleton -->
  <TabsSkeleton count={2} />
{:else}
  <!-- Tab headers -->
  <div class="bx--tabs bx--tabs--container">
    <ul class="bx--tabs__nav">
      {#each Object.keys(data) as cat}
        <CTabItem
          label={cat}
          active={cat == activeTab}
          on:click={event => handleTabClick(event)} />
      {/each}
    </ul>
  </div>
  <!-- The list/grid of products -->
  {#key activeTab}
    <div class="tab-content" in:fly={{ x: -25 }}>
      {#each data[activeTab] as p}
        <ProductTile product={p} />
      {/each}
    </div>
  {/key}
{/if}
</div>
