<script>
  import { createEventDispatcher } from "svelte";

  export let active = false;
  export let label = "";
  const dispatch = createEventDispatcher();

  function handleClick(label) {
    dispatch("click", label);
  }
</script>

<style>
  :global(.bx--tabs__nav-item) {
    color: rgba(0, 0, 0, 0.85);
  }

  :global(.bx--tabs__nav-link.bx--tabs__nav-link:visited) {
    color: rgba(0, 0, 0, 0.85);
  }
</style>

<li class="bx--tabs__nav-item" class:bx--tabs__nav-item--selected={active}>
  <a
    href="javascript:;"
    class="bx--tabs__nav-link"
    on:click={() => dispatch('click', { label: label })}>
    {label}
  </a>
</li>
