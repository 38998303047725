<script>
  import { onMount } from "svelte";
  import { authorize, fetchProducts } from "./db.js";
  import Router from "svelte-spa-router";

  // Carbon components
  import { ButtonSet, Button } from "carbon-components-svelte";
  import {
    ClickableTile,
    Tile,
    Accordion,
    AccordionItem
  } from "carbon-components-svelte";
  //import "carbon-components-svelte/css/g10.css";

  // Custom components
  import Nav from "./components/Nav.svelte";
  import Logo from "./components/Logo.svelte";
  import TabbedProducts from "./components/TabbedProducts.svelte";

  // Page components
  import LandingPage from "./routes/LandingPage.svelte";
  import AllProductsPage from "./routes/AllProductsPage.svelte";
  import ProductPage from "./routes/ProductPage.svelte";
  import CartPage from "./routes/CartPage.svelte";

  // Stored data
  import { apikey, products as products_store, cart } from "./stores.js";

  let api_key;
  let products = [];

  $: if ($products_store) {
    products = $products_store;
  }

  const unsub = apikey.subscribe(val => {
    api_key = val;
  });

  cart.useLocalStorage();

  const routes = {
    "/product/:id": ProductPage,
    "/category/:id": AllProductsPage,
    "/brand/:id": AllProductsPage,
    "/products/": AllProductsPage,
    "/cart": CartPage,
    "/": LandingPage
  };

  onMount(async () => {
    //await authorize();
    products = await fetchProducts();
    $products_store = products;
  });
</script>

<style lang="scss">
  /*:global(*) {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  }*/

  .root {
  }

  .brand {
    /*font-size: 3em;
    font-family: fantasy;
    background-color: white;
    width: fit-content;
    padding: 2px 4px;
    filter: invert(1);*/
  }

  .brand img {
    height: 90px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 960px;
    margin: 0 auto;
    transition: all 0.3s ease;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 100%;
  }

  :global(.link) {
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-grow: 1;
    color: rgba(255, 255, 255, 0.8);
  }

  @media screen and (max-width: 1366px) {
    .content {
      width: 75%;
    }
  }

  @media screen and (max-width: 960px) {
    .content {
      width: 100%;
    }
  }
</style>

<div class="root">
  <div class="content">
    <Nav />
    <Router {routes} restoreScrollState={true} />
  </div>

</div>
